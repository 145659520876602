export default {
  firebase_service: {
    error_codes: {
      // Covers fetchSignInMethodsForEmail, signInWithEmailAndPassword, sendPasswordResetEmail, verifyPasswordResetCode,
      // confirmPasswordReset
      'auth/invalid-email': '@:(errors.emailInvalidFormat)',
      'auth/user-disabled': 'Ce compte utilisateur a été bloqué par un administrateur.',
      'auth/user-not-found': '@:(errors.accountNotFound)',
      'auth/wrong-password': '@:(errors.passwordIsWrong)',
      'auth/weak-password': "Le mot de passe n'est pas assez fort",
      'auth/too-many-requests':
        "En raison d'une activité inhabituellement élevée, nous avons bloqué toutes les demandes de cet appareil. Veuillez réessayer ultérieurement.",
      'auth/expired-action-code': 'Le code de réinitialisation du mot de passe est expiré.',
      'auth/invalid-action-code':
        "Le code de réinitialisation du mot de passe n'est pas valable. Le code est erroné ou a déjà été utilisé.",
      'auth/user-token-expired':
        "Le jeton de cet utilisateur n'est plus valable. L'utilisateur doit se reconnecter.",
      'auth/invalid-user-token': "Le jeton Refresh n'est pas valable.",
      'auth/invalid-credential': "Le type de subvention indiqué n'est pas valide.",
    },
  },
  errors: {
    emailRequired: "L'adresse e-mail est obligatoire.",
    emailInvalidFormat: "L'adresse e-mail n'est pas correctement formatée.",
    passwordRequired: 'Le mot de passe est obligatoire.',
    passwordTooShort: 'Le mot de passe saisi est trop court.',
    passwordIsWrong: "Ce mot de passe n'est pas valable.",
    accountNotFound: 'Cet utilisateur n’est pas connu.',
    passwordpasswordMismatch: 'Les mots de passe ne correspondent pas',
    passwordFormatIsInvalid:
      'Le mot de passe doit contenir au moins 1 lettre majuscule, 1 lettre minuscule et 1 chiffre.',
  },
  language_display_names: {
    de: 'Allemand',
    fr: 'Français',
    en: 'Anglais',
  },

  generic_crud_labels: {
    save: 'Enregistrer',
    cancel: 'Annuler',
    back: 'Retour',
    next: 'Continuer',
    edit: 'Modifier',
    preview: 'Aperçu',
    print: 'Imprimer',
    delete: 'Effacer',
    duplicate: 'Dupliquer',
  },
  order_footer: {
    internal_processing_mark: 'Note de traitement usine GALVASWISS {locationName} interne',
    in: 'Entrée',
    out: 'Sortie',
    door_nr: 'Tours no',
    delivery_date: 'Date de livraison',
    delivery_date_nocommitment: 'unverbindlicher Liefertermin',
    area_door: 'Région/Tour',
    lot: 'N° de lot',
    car: 'Camion à moteur',
    trailer: 'Remorque',
    lorry: 'Semi-remorque',
    loading_position: 'Position de chargement',
  },
  order_body: {
    no_treatment: 'Pas de traitement',
    print_instructions:
      'Veuillez imprimer le bon de livraison électronique et le remettre au chauffeur.',
    print_warning: 'Attention : le bon de livraison imprimé et transmis ne peut plus être modifié',
    print_and_send: 'Imprimer et transmettre',
    cancel: 'Annuler',
    no_treatment_warning:
      "Vous n'avez pas choisi de traitement, vous voulez quand même enregistrer sans type de traitement?",
    no_treatment_warning_continue:
      "Vous n'avez pas choisi de traitement, vous voulez quand même continuer sans type de traitement?",
    shipping_address_warning: 'Veuillez indiquer votre adresse de livraison',
    no_transport_option_selected:
      'Aucune option de transport a été choisie pour la livraison et le retour!',
    no_transport_option_hint:
      "Veuillez choisir l'option de transport désirée pour la livraison et le retour.",
    empty_shipping_address: 'Adresse de livraison non valide',
    print_order: 'Imprimer le e-bon de livraison',
    no_treatment_available: 'Aucun traitement existant',
    print_and_send_confirm: 'Imprimer et transmettre le bon de livraison électronique?',
    transport_option: 'Option de transport',
    title: 'Titre',
    no_threads_title: 'Gewinde reinigen',
  },
  order_header: {
    order_human_readable_id: 'Bon de livraison:',
    order_label: 'Bon de commande / bon de livraison',
    important: 'Important:',
    important_second: 'Donner une copie au {name}',
    phone: 'Téléph.',
    fax: 'Téléfax',
    email: 'Email',
    web: 'Web',
    billing_address_label: 'Adresse de facturation',
    site_address: "Adresse d'expédition / de chantier",
    offer_nr: "N° d'offre::",
    delivery_date_main: 'Date de livraison:',
    pickup_date: 'Date de retrait:',
    delivery_date_nocommitment: 'unverbindlicher Liefertermin:',
    date: 'Date',
    order_nr: 'N° de commande',
    commision: 'Commission',
    order_number: 'N° de bon de livraison',
    firstname: 'Prénom',
    lastname: 'Nom',
    enter_address: "Saisir l'adresse",
    zip: 'XXXX',
    company: "Saisir le nom de l'entreprise",
    city: 'Ville',
  },
  order_list: {},
  order_item: {
    preview: 'Aperçu',
    print: 'Imprimer',
    edit: 'Modifier',
    delete: 'Effacer',
    duplicate: 'Dupliquer',
  },
  order_metal_components: {
    internal: 'Interne',
    per_order: 'Par commande, veuillez uniquement',
    per_order_note_stress: 'une',
    per_order_note_end: 'Type de traitement',
    tin_bath: 'Bain de zinc : 16.0m de longueur, 2.1m de largeur, 3.0m de hauteur',
    position: 'Pos',
    quantaty: 'Quantité',
    description: 'Désignation du matériel',
    image: 'Image',
    dimentions: 'Profils & dimensions',
    length: 'Longueur mm',
    width: 'Largeur mm',
    depth: 'Profondeur mm',
    position_long: 'Position',
    add_order_line: 'Ajouter position de commande',
  },
  order_more_options: {
    treating: 'Traitement',
    iso: 'Galvanisation à chaud ISO 1461',
    tolerance_zone: 'DASt022 Zone de confiance:',
    only_zync: 'Décapage du zinc uniquement et retour noir',
    duroplex: 'Duroplex',
    fvz: '(FVZ + laque liquide)',
    thermoplex: 'Thermoplex',
    fvz_powder: '(FVZ + poudre)',
    select_color_tone: 'Sélectionner une couleur',
    provide_color_tone: 'Saisissez une couleur',
    ral: 'Couleur',
    sz: 'S + Z',
    ferro: 'Ferrostyle-N (Peinture liquide uniquement)',
    ferrow_only_powder: 'Ferrostyle-P (Poudre uniquement)',
    select_color_tone_and_application: "Choisir la couleur & l'application",
    please_provide_color_tone_and_application:
      'Saisissez une couleur et sélectionnez une application.',
    inner_use: 'Application intérieure',
    you_must_select: '(à mentionner impérativement!)',
    outside_use: 'Application extérieure',
    galva: 'Galvanisation par électrolyse',
    galva_duration: '(env. 5 - 10 jours ouvrables)',
    glanz: 'Chromage brillant',
    blue: 'Chromage bleu',
    en1090: 'EN 1090',
    fill_en1090: 'Veuillez remplir la catégorie EN 1090. Si désiré',
    corrosion: 'Catégorie de corrosivité:',
    preparation_level: 'Degré de préparation:',
    specials: 'Spécial',
    clean: 'Nettoyer le filetage',
    clean_thread_in: 'Innengewinde reinigen',
    clean_thread_out: 'Aussgewinde reinigen',
    pcs: 'pcs.',
    qs: 'QS',
    measurment_log: 'Protocole de mesure standard Fr. 50',
    measurment_log_duro: 'Protocole de mesure selon les spécifications du client, sur demande',
    measurment_log_de: 'Messprotokoll für Feuerverzinken',
    measurment_log_duro_de: 'Messprotokoll für Duroplex',
    holes: "Trous percés à l'intérieur",
    holes_closed: 'Fermer les trous',
    no_holes: 'Ne pas percer de trous supplémentaires',
    remove_zync: '* Eliminer/décaper le zinc',
    more_cost: '(coûts additionnels)',
    old_color: '* Anicienne peinture lessiver chez le sous-traitant',
    old_color_de: "* Brûler l'ancienne couleur chez le sous-traitant",
    old_color_aarberg: "* Brûler l'ancienne couleur chez le sous-traitant",
    price_on_request: '(prix sur demande)',
    only_with_meeting: '* Nécesite une prolongation du délai!',
    pack: 'Emballage',
    eu_pallets: 'Euro-palettes',
    quantaty_pallets: 'Nombre:',
    pallet_frames: 'Cadres de palettes',
    half_pallete: '½ cadre de palette',
    one_way_pallete: 'Palettes à usage unique',
    other: 'Divers',
    transport: 'Transport',
    deliver_to: "Livraison à l'usine {locationName}",
    lorry_galva: 'Camion Galvaswiss',
    self: 'Moi-même',
    returns: 'Retour de livraison départ usine {locationName}',
    terms_notes: 'Remarque sur les CGV',
    terms: 'Conditions générales de vente de',
    comments: 'Remarques / Personne à contacter en cas de questions',
  },
  auth_form: {},
  navi: {
    search_orders: 'Recherche ..',
    drafts: 'Brouillons',
    sent: 'Transmis à',
    archived: 'Archivé',
    create: 'Créer bon',
    create_order: 'Créer bon de livraison',
    create_note: 'Créez votre bulletin de livraison digitale pour Galvaswiss AG',
    support: 'Support',
    account_management: 'Gestion de compten',
    logoff: 'Déconnecter',
    welcome: 'Bienvenue sur la plateforme de bons de livraison électroniques de Galvaswiss AG',
    change_password: 'Modifier le mot de passe',
    new_password: 'Nouveau mot de passe',
    confirm_new_password: 'Confirmer le nouveau mot de passe',
    password_changed: 'Le mot de passe a été modifié',
    i_accept: "J'accepte les",
    terms_and_conditions: 'Conditions générales de vente',
    from: 'de la société Galvaswiss AG.',
    data_security: 'Déclaration de protection des données',
    register: 'Enregistrer',
  },
  auth_new_password: {
    email_sent_confirmation: 'Nous leur avons envoyé un lien de confirmation à',
    email_sent_confirmation_end: 'envoyé par courrier électronique.',
    enter_email: "Saisir l'adresse e-mail de récupération de compte.",
    send: 'Envoyer',
    to_login: 'Pour se connecter',
  },
  auth_set_new_password: {
    enter_new_password: 'Saisir un nouveau mot de passe pour',
    enter_new_password_end: 'marche',
    enter_new_password_again: 'Saisissez un nouveau mot de passe.',
  },
  signin: {
    login_to_start: 'Connectez-vous pour modifier vos bons de livraison.',
    forgot_password: 'Mot de passe oublié?',
    login: 'Connecter',
    password: 'Mot de passe',
    register: 'Enregistrer',
  },
  order_edit: {
    edit_order: 'Édition du bon de livraison',
    edit_for: 'Édition de',
    save_to_draft: 'Enregistrer dans les brouillons',
    print_and_send: 'Imprimer et transmettre',
  },
  orders_view: {
    orders: 'Bons',
    your_orders: 'Vos bons de livraison',
    search_results: 'Résultats de recherche pour ',
    no_search_results: 'Aucun résultat de recherche trouvé.',
    no_orders_yet: "Vous n'avez pas encore créé de bons de livraison.",
    no_orders_drafts: "Il n'y a pas de projet de bon de livraison.",
    no_orders: "Vous n'avez pas encore créé de bons de livraison.",
    no_pending_orders: "Vous n'avez pas de bons de livraison en attente.",
    archive_orders_note: 'Les bons de livraison archivés sont conservés pendant 12 mois maximum.',
    no_archived_orders: "Vous n'avez pas de bons de livraison archivés.",
    delete_order: 'Supprimer le bon de livraison?',
    delete_confirm: 'Êtes-vous sûr de vouloir supprimer ce bon de livraison?',
  },
  user_profile: {
    my_account_short: 'Mon compte',
    my_account_text: 'Mon compte - Organisation du compte',
    sign_out_text: 'Déconnecter',
    change_name_title: 'Modifier le nom',
    change_name_label_text: 'Prénom et nom',
    change_name_placeholder_text: 'Saisir un nouveau nom',
    change_password_label_text: 'Modifier le mot de passe',
    change_password_placeholder_text: 'Nouveau mot de passe',
    change_password_confirm_placeholder_text: 'Confirmer le nouveau mot de passe',
    error_message_username_text: 'Saisissez un nom.',
    error_message_new_username_text: 'Saisissez un nouveau nom.',
    select_language_label_text: 'Langue préférée',
    select_language_placeholder_text: 'Choisissez votre langue préférée.',
    language_saved_text: 'Langue enregistrée',
    option_saved_text: 'Option enregistrée',
    additional_options_text: 'Options supplémentaires',
  },
  check_password: {
    lenght_limit: 'Le mot de passe doit contenir au moins 8 caractères',
    complexity_level:
      'Le mot de passe doit contenir au moins 1 lettre majuscule, 1 lettre minuscule et 1 chiffre.',
    password_matches: 'Les mots de passe ne correspondent pas',
    password_matches_username: 'Le mot de passe ne doit pas contenir le nom du compte',
  },
};
